import React from 'react';
import styled from 'styled-components';
import Img from '../../atoms/Img';

import { assetize } from '../../../utils/pureUtils';
import Box from '../../atoms/Box';

const StyledLogoWrapper = styled('div')(({ theme }) => ({
  color: theme.colors.greyBluedarker,
  whiteSpace: 'nowrap',
  overflow: 'auto',
  width: 'auto',
  paddingRight: theme.space[4],
  paddingLeft: theme.space[4],
  img: {
    display: 'inline-block',
    height: '50px'
  },
  [`@media (max-width: ${theme.breakpoints.md})`]: {
    paddingRight: 0,
    width: '100%'
  }
}));

const TrustedByBanner = () => {
  return (
    <StyledLogoWrapper className="no-scrollbar">
      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="6px">
        <Img
          alt="deloitte logo"
          src={assetize('/partner-clients/deloitte.webp')}
          srcRetina={assetize('/partner-clients/deloitte@2x.webp')}
          fallback={assetize('/partner-clients/deloitte.png')}
          fallbackRetina={assetize('/partner-clients/deloitte@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="1">
        <Img
          alt="accountable logo"
          src={assetize('/partner-clients/accountable.webp')}
          srcRetina={assetize('/partner-clients/accountable@2x.webp')}
          fallback={assetize('/partner-clients/accountable.png')}
          fallbackRetina={assetize('/partner-clients/accountable@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="6px">
        <Img
          alt="finaxys logo"
          src={assetize('/partner-clients/finaxys.webp')}
          srcRetina={assetize('/partner-clients/finaxys@2x.webp')}
          fallback={assetize('/partner-clients/finaxys.png')}
          fallbackRetina={assetize('/partner-clients/finaxys@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="5px">
        <Img
          alt="labbox logo"
          src={assetize('/partner-clients/labbox.webp')}
          srcRetina={assetize('/partner-clients/labbox@2x.webp')}
          fallback={assetize('/partner-clients/labbox.png')}
          fallbackRetina={assetize('/partner-clients/labbox@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="4px">
        <Img
          alt="maltem logo"
          src={assetize('/partner-clients/maltem.webp')}
          srcRetina={assetize('/partner-clients/maltem@2x.webp')}
          fallback={assetize('/partner-clients/maltem.png')}
          fallbackRetina={assetize('/partner-clients/maltem@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="6px">
        <Img
          alt="positive thinking company logo"
          src={assetize('/partner-clients/ptc.webp')}
          srcRetina={assetize('/partner-clients/ptc@2x.webp')}
          fallback={assetize('/partner-clients/ptc.png')}
          fallbackRetina={assetize('/partner-clients/ptc@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="4px">
        <Img
          alt="publicis logo"
          src={assetize('/partner-clients/publicis.webp')}
          srcRetina={assetize('/partner-clients/publicis@2x.webp')}
          fallback={assetize('/partner-clients/publicis.png')}
          fallbackRetina={assetize('/partner-clients/publicis@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center">
        <Img
          alt="securex logo"
          src={assetize('/partner-clients/securex.webp')}
          srcRetina={assetize('/partner-clients/securex@2x.webp')}
          fallback={assetize('/partner-clients/securex.png')}
          fallbackRetina={assetize('/partner-clients/securex@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="4px">
        <Img
          alt="strobbo logo"
          src={assetize('/partner-clients/strobbo.webp')}
          srcRetina={assetize('/partner-clients/strobbo@2x.webp')}
          fallback={assetize('/partner-clients/strobbo.png')}
          fallbackRetina={assetize('/partner-clients/strobbo@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="8px" transform="translateY(2px)">
        <Img
          alt="the synergist logo"
          src={assetize('/partner-clients/thesynergist.webp')}
          srcRetina={assetize('/partner-clients/thesynergist@2x.webp')}
          fallback={assetize('/partner-clients/thesynergist.png')}
          fallbackRetina={assetize('/partner-clients/thesynergist@2x.png')}
          lazyloading={true}
        />
      </Box>

      <Box mr="3" bg="#FFF" borderRadius="2" px="3" height="62px" display="inline-flex" alignItems="center" pt="6px" transform="translateY(1px)">
        <Img
          alt="world of digits logo"
          src={assetize('/partner-clients/wod.webp')}
          srcRetina={assetize('/partner-clients/wod@2x.webp')}
          fallback={assetize('/partner-clients/wod.png')}
          fallbackRetina={assetize('/partner-clients/wod@2x.png')}
          lazyloading={true}
        />
      </Box>
    </StyledLogoWrapper>
  );
};

export default TrustedByBanner;
