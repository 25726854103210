import React from 'react';

import styled from 'styled-components';
import { oneOfType, number, node, string, func, shape, bool, object, oneOf } from 'prop-types';

const StyledButtonEffect = styled('span')({
  position: 'absolute',
  inset: '0px',
  borderRadius: '8px',
  opacity: 0,
  transitionproperty: 'opacity',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '300ms',
  backgroundImage: `radial-gradient(
      circle at center center,
      rgb(16, 185, 129) 0%,
      rgb(15, 171, 119) 27.5%,
      rgb(17, 196, 137) 40%,
      rgb(4, 120, 87) 57.5%,
      rgb(5, 156, 113) 100%
    )`,
  backgroundPosition: `calc((100 - var(--mouse-x, 0)) * 1%)
      calc((100 - var(--mouse-y, 0)) * 1%)`,
  backgroundSize: '200% 200%',
  transform: 'scale(1)'
});

const StyledButton = styled('button')({
  color: '#fff',
  position: 'relative',
  background: '#ffffff',
  padding: '16px 26px',
  fontWeighgt: '700',
  cursor: 'pointer',
  borderRadius: '8px',
  border: 'none',
  boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  overflow: 'hidden',
  backgroundImage: `linear-gradient(
    to right,
    rgb(27, 155, 119) 0%,
    rgb(24, 139, 107) 50%,
    rgb(21, 121, 93) 100%
  )`,
  [`&:hover ${StyledButtonEffect}`]: {
    opacity: '1'
  }
});

const StyledChildren = styled('span')({
  position: 'relative',
  zIndex: '1',
  pointerEvents: 'none'
});

const ButtonGradient = React.forwardRef(({ children, buttonProps, ...props }, ref) => {
  return (
    <StyledButton
      {...buttonProps}
      {...props}
      ref={ref}
      onMouseMove={e => {
        const mouseX = e.pageX - e.currentTarget.offsetLeft;
        const mouseY = e.pageY - e.currentTarget.offsetTop;
        e.currentTarget.style.cssText = `
          --mouse-x: ${mouseX}; 
          --mouse-y: ${mouseY};
        `;
      }}>
      <StyledButtonEffect />
      <StyledChildren>{children}</StyledChildren>
    </StyledButton>
  );
});

ButtonGradient.propTypes = {
  children: node,
  variant: oneOf(['classic', 'small']),
  type: string,
  shape: string,
  size: string,
  borderRadius: number,
  styleType: oneOfType([string, number, object]),
  getRef: func,
  loading: bool,
  buttonProps: shape({
    name: string,
    type: string,
    onClick: func,
    disabled: bool,
    iconOnly: bool
  })
};

export default ButtonGradient;
