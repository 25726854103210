import React from 'react';
import { translateArray, useTranslate } from '../../translate/I18n';
import Box from '../atoms/Box';
import Link from '../atoms/Link';
import Text from '../atoms/Text';
const BeelanceRoles = () => {
  const { t } = useTranslate(['roles']);
  return (
    <Box display="flex" justifyContent="flex-start" gap="24px" flexDirection={{ _: 'column', lg: 'row' }}>
      {translateArray(t, 'roles:categories').map(({ categorie, roles }, index) => (
        <Box background="white" borderRadius="2" py="2" px="3" flex="1" key={index}>
          <Text fontSize="20px" fontWeight="700" mb={3}>
            {categorie}
          </Text>
          {roles.map(
            role =>
              role &&
              role.label && (
                <Box key={role.label}>
                  {role.path && (
                    <Link to={role.path || ''}>
                      <Text color="#111517" fontWeight="500" textDecoration="underline">
                        {role.label}
                      </Text>
                    </Link>)}
                  {!role.path && (
                    <Text color="#111517" fontWeight="500">
                      {role.label}
                    </Text>)}
                </Box>
              )
          )}
        </Box>
      ))}
    </Box>
  );
};
export default BeelanceRoles;
