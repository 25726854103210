import React from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';

const StyledImage = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain'
}));

const Img = ({ src, srcRetina, fallback, fallbackRetina, alt, lazyloading = false, className }) => {
  return (
    <picture className={className}>
      <source srcSet={`${src} 1x${srcRetina ? `, ${srcRetina} 2x` : ''}`} type={'image/webp'} />
      <StyledImage
        src={fallback}
        srcSet={fallbackRetina && `${fallbackRetina} 2x`}
        alt={alt}
        title={alt}
        {...(lazyloading ? { loading: 'lazy' } : {})}
        decoding="async"
      />
    </picture>
  );
};

Img.propTypes = {
  src: string,
  srcRetina: string,
  fallback: string,
  fallbackRetina: string,
  alt: string,
  lazyloading: bool,
  className: string
};

export default Img;
