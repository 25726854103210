import React from 'react';
import { translateArray, useTranslate } from '../../translate/I18n';
import PropTypes from 'prop-types';
import Box from '../atoms/Box';
import Text from '../atoms/Text';
import styled, { keyframes } from 'styled-components';

const scroll = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(-100%)' }
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  animation: ${scroll} ${props => `${props.elements * 20}s` || '40s'} linear infinite;
`;

const StyledContainer = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  [`&:hover ${StyledWrapper}`]: {
    animationPlayState: 'paused'
  }
});

const Testimonials = ({ role }) => {
  const { t, i18n } = useTranslate();

  return (
    <StyledContainer>
      {[...Array(2)].map((e, i) => (
        <StyledWrapper key={e} elements={translateArray(t, `testimonials:${role}Testimonials`).length}>
          {translateArray(t, `testimonials:${role}Testimonials`).map(
            (testimonials, index) => {
              if (!testimonials) return null;
              const { name, position, quote, photoPath, companyName } = testimonials;
              return (
                <Box
                  bg="white"
                  borderRadius="2"
                  key={name + ' ' + position + index}
                  mr="24px"
                  p={3}
                  width="400px"
                  aria-hidden={i === 0 ? 'false' : 'true'}>
                  <Box display="flex">
                    {photoPath && (
                      <Box borderRadius="46px" overflow="hidden" display="inline-block" width="46px" height="46px" mr="2">
                        <img width="46" height="46" src={`/assets/testimonials/${photoPath}`} alt="profile" />
                      </Box>
                    )}
                    <Box>
                      <Text tag="h4" fontSize="18px" lineHeight="24px" fontWeight="600">
                        {name}
                      </Text>
                      <Text fontSize="14px" color="#787B7D" lineHeight="24px" fontWeight="500" mb={2}>
                        {position} {companyName ? `• ${companyName}` : ''}
                      </Text>
                    </Box>
                  </Box>
                  <Text>{quote}</Text>
                </Box>
              );
            }
          )}
        </StyledWrapper>
      ))}
    </StyledContainer>
  );
};

Testimonials.propTypes = {
  role: PropTypes.string
};

export default Testimonials;
