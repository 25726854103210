import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const ArrowUpCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.852 7.548a1.2 1.2 0 00-.396-.252 1.2 1.2 0 00-.912 0 1.2 1.2 0 00-.396.252l-3.6 3.6a1.205 1.205 0 101.704 1.704l1.548-1.56V15.6a1.2 1.2 0 102.4 0v-4.308l1.548 1.56a1.2 1.2 0 001.704 0 1.2 1.2 0 000-1.704l-3.6-3.6zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 21.6a9.6 9.6 0 110-19.2 9.6 9.6 0 010 19.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default ArrowUpCircle;
