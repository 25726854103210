import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowUpCircle from '../atoms/icons/ArrowUpCircle';

const topFunction = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const StyledContainer = styled('button')(({ theme, display }) => ({
  display: display,
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  margin: 'auto',
  zIndex: 99,
  border: 'none',
  outline: 'none',
  backgroundColor: 'rgb(255,255,255)',
  cursor: 'pointer',
  padding: '15px',
  borderRadius: '50px',
  boxShadow: '0 10px 20px 0 rgba(64,64,64,0.10)',
  fontSize: '18px',
  [`@media (min-width: ${theme.breakpoints.lg})`]: {
    display: 'none'
  }
}));

const BackToTop = () => {
  const [displayButton, setDisplayButton] = useState('none');

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  const scrollHandler = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      setDisplayButton('block');
    } else {
      setDisplayButton('none');
    }
  };
  return (
    <StyledContainer display={displayButton} onClick={() => topFunction()}>
      <ArrowUpCircle color="#111517" fontSize="24px" />
    </StyledContainer>
  );
};

export default BackToTop;
